import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import { connect } from "react-redux";
import {
	getResumesAction,
	deleteResumeAction,
	ArchiveResume,
	UnArchiveResume,
	searchEmployees,
	FilterEmployees,
} from "../../actions/resumesActions";
import {
	getEmployeesAction,
	deleteEmployeeAction,
} from "../../actions/employeesActions";
import ResumesTableActionsCell from "../elements/ResumesTableActionsCell";
import getStaticUrl from "../../helpers/api";
import DeleteModal from "../modals/DeleteModal";
import history from "../../routes/history";
import EmployeesTableActionsCell from "../elements/EmployeesTableActionsCell";
import PdfModal from "../modals/PdfModal";
import { onRowClick } from "../../helpers/ViewPdf";
import { FilterField, GeneralFilter } from "../../helpers/GeneralFillter";
import MaterialIcon from "../elements/MaterialIcon";
import { Col, Row } from "react-bootstrap";
import ArchiveActionCell from "../elements/ArchiveActionCells";
import ArchiveModel from "../modals/ArchiveModal";

const ResumesTable = ({
	resumes,
	employees,
	getResumesAction,
	deleteResumeAction,
	ArchiveResume,
	UnArchiveResume,
	getEmployeesAction,
	archive,
	deleted,
	SearchRow,
	SearchResults,
	FilterEmployees,
	handelPageSizeFilter,
}) => {
	const [ShowPdf, setShowPdf] = useState({
		status: false,
		rowId: "",
		Type: "employee",
	});
	let index = -1;
	let pageNr = employees.data.length
		? employees.meta.pagination.current_page - 1
		: 0;
	const totalPages = employees.data.length
		? employees.meta.pagination.total_pages
		: 1;
	const [showDelete, setShowDeleteModal] = useState(-1);
	const [showArchive, setArchiveModal] = useState(-1);
	const [Fillter, setFillter] = useState(null);
	const [counterPages, setCounterPages] = useState(100);

	if (history.location.pathname.includes("/resumes")) {
		handelPageSizeFilter(counterPages);
	}
	useEffect(() => {
		// fillter data if user type in general filter input
		GeneralFilter(SearchRow, employees, setFillter);
	}, [SearchRow]);

	useEffect(() => {
		console.log(typeof SearchResults);
		if (Array.isArray(SearchResults)) {
			setFillter(SearchResults);
		} else {
			setFillter(null);
		}
	}, [SearchResults]);

	const columns = [
		{
			Header: "ID",
			width: 50,
			filterable: false,
			Filter: FilterField,
			accessor: "id",
			filterMethod: (filter, row) => {
				if (String(row[filter.id]).includes(String(filter.value)))
					return row;
			},
			Cell: (row) => row.index + 1 || "",
		},
		{
			Header: "First name",
			width: 200,
			filterable: true,
			Filter: FilterField,
			accessor: "first_name",
			Cell: (cellProps) => cellProps.value || "",
		},
		{
			Header: "Last name",
			width: 200,
			filterable: true,
			Filter: FilterField,
			accessor: "last_name",
			Cell: (cellProps) => cellProps.value || "",
		},
		{
			Header: "Email",
			width: 260,
			filterable: true,
			Filter: FilterField,
			accessor: "email",
			Cell: (cellProps) => cellProps.value || "",
		},
		{
			Header: "Phone",
			width: 220,
			filterable: true,
			Filter: FilterField,
			accessor: "phone",
			Cell: (cellProps) => cellProps.value || "",
		},
		{
			Header: "Level",
			width: 200,
			filterable: true,
			Filter: FilterField,
			accessor: "employee_level",
			filterMethod: (filter, row) => {
				if (
					row[filter.id].name
						.toLowerCase()
						.includes(filter.value.toLowerCase())
				) {
					return row;
				}
			},
			Cell: (cellProps) =>
				(
					<div
						onClick={() =>
							FilterEmployees(
								[
									{
										id: "employee_level",
										value: cellProps.value.name,
									},
								],
								archive ? archive : false
							)
						}
					>
						{cellProps.value.name}
					</div>
				) || "",
		},
		{
			Header: "Top 4 Stacks",
			accessor: "abilities",
			filterable: false,
			width: 420,
			Cell: (cellProps) => {
				return cellProps.value
					? cellProps.value
							.sort((a, b) => b.level - a.level)
							.map((e) =>
								e.skill
									? e.area.name === "SAP" ||
									  e.area.name === "Frameworks" ||
									  e.area.name ===
											"Programming languages " ||
									  e.area.name === "Libraries"
										? e
										: false
									: ""
							)
							.filter(Boolean)
							.slice(0, 4)
							.map((e) => (
								<div
									className="skills-column"
									onClick={() =>
										FilterEmployees(
											[
												{
													id: "skill_id",
													value: e.skill.id,
												},
											],
											archive ? archive : false
										)
									}
								>
									<span>{`${e.skill.name} (${e.level})`}</span>
								</div>
							))
					: "";
			},
		},
		{
			Header: "Actions",
			width: 190,
			filterable: false,
			style: {
				overflow: "unset",
			},
			Cell: (cellProps) =>
				!archive ? (
					<EmployeesTableActionsCell
						Route="resumes/Waw"
						cellProps={cellProps}
						setShowDeleteModal={setShowDeleteModal}
						setArchiveModal={setArchiveModal}
						newResume={false}
					/>
				) : (
					<ArchiveActionCell
						cellProps={cellProps}
						setShowDeleteModal={setShowDeleteModal}
						setArchiveModal={setArchiveModal}
						showArchive={showArchive}
						newResume={false}
					/>
				),
		},
	];
	const handleCloseDelete = () => {
		setShowDeleteModal(-1);
	};
	const handleCloseArchive = () => {
		setArchiveModal(-1);
	};
	useEffect(() => {
		console.log("archive", archive);
		pageNr = totalPages - 1;
		employees.data = [];
		const getDataEmployee = async () => {
			const { filters } = employees;
			await getEmployeesAction({
				pageSize: counterPages,
				sorted: null,
				filters,
				page: 1,
				archive,
				deleted,
			});
		};
		getDataEmployee();
	}, [archive, counterPages]);

	useEffect(() => {
		if (
			history.location.pathname.includes("/resumes") &&
			history.location?.state?.createEmp
		) {
			employees.data = [];
			const getDataEmployee = async () => {
				const { filters } = employees;
				await getEmployeesAction({
					pageSize: counterPages,
					sorted: null,
					filters,
					page: totalPages,
					archive,
					deleted,
				});
			};
			getDataEmployee();
		}
	}, [employees.meta.pagination.total]);

	const handlePaginateFetch = (page) => {
		const { filters } = employees;
		getEmployeesAction({
			pageSize: counterPages,
			sorted: null,
			filters,
			page: page + 1,
			archive,
			deleted,
		});
	};
	const handleDelete = async () => {
		const { id } = employees.data[index];
		await deleteResumeAction(id, handleCloseDelete, true);

		// When user delete a resume from Archived Resumes page, resumes not archived resumes display on Archived resumes list too
		if (!history.location.pathname === "archive") {
			if (employees.data.length === 1) {
				const { filters } = employees;
				getEmployeesAction({
					pageSize: counterPages,
					sorted: null,
					filters,
					page: 1,
					archive,
					deleted,
				});
			}
			const { filters } = employees;
			await getEmployeesAction({
				pageSize: counterPages,
				sorted: null,
				filters,
				page: pageNr + 1,
				archive,
				deleted,
			});
		}
		if (history.location.pathname.includes("archive")) {
			if (employees.data.length === 1) {
				const { filters } = employees;
				await getEmployeesAction({
					pageSize: counterPages,
					sorted: null,
					filters,
					page: 1,
					archive,
					deleted,
				});
			}
		}
	};
	const handleArchive = async () => {
		const { id } = employees.data[index];
		console.log("Archive", employees.data);
		await ArchiveResume(id, handleCloseArchive, true);
		const { filters } = employees;
		await getEmployeesAction({
			pageSize: counterPages,
			sorted: null,
			filters,
			page: 1,
			archive: false,
			deleted,
		});

		if (employees.data.length === 1) {
			const { filters } = employees;
			await getEmployeesAction({
				pageSize: counterPages,
				sorted: null,
				filters,
				page: 1,
				archive: false,
				deleted,
			});
		}
	};
	const HandleUnArchive = () => {
		const { id } = employees.data[index];
		UnArchiveResume(id, handleCloseArchive, true);
		if (employees.data.length === 1) {
			const { filters } = employees;
			getEmployeesAction({
				pageSize: counterPages,
				sorted: null,
				filters,
				page: 1,
				archive: true,
				deleted,
			});
		}
	};

	if (showDelete >= 0) {
		index = showDelete;
	}
	if (showArchive >= 0) {
		index = showArchive;
	}
	// filter data by serverside
	const FilterData = async (column, value) => {
		console.log(column);
		await FilterEmployees(column, archive ? archive : false); // send archive to serach on archive data or not archived
	};
	console.log("employees.data", employees.data);
	return (
		<div className="table-style resumes-employees">
			{ShowPdf.status && <PdfModal status={{ ShowPdf, setShowPdf }} />}
			<ReactTable
				data={
					Fillter
						? Fillter.sort((a, b) => a.first_name - b.first_name)
						: employees.data.sort((a, b) => {
								if (
									a.first_name.toLowerCase() <
									b.first_name.toLowerCase()
								) {
									return -1;
								}
								if (
									a.first_name.toLowerCase() >
									b.first_name.toLowerCase()
								) {
									return 1;
								}
								return 0;
						  })
				}
				loading={employees.isLoading}
				columns={columns}
				defaultPageSize={15}
				pages={totalPages}
				page={pageNr}
				filterable
				manual // for handle pagination
				// defaultFilterMethod={(filter,row) => {
				// 	if(row[filter.id].toLowerCase().includes(filter.value.toLowerCase())){
				// 		return row
				// 	}
				// }}
				onFilteredChange={FilterData}
				showPageSizeOptions={true}
				pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
				sortable={false}
				className={`striped-highlight cl-ta remove-id unique-filter-table ${
					!archive && "center-action"
				}`}
				onPageChange={handlePaginateFetch}
				getTdProps={(state, rowInfo, column, instance) =>
					onRowClick(
						state,
						rowInfo,
						column,
						instance,
						setShowPdf,
						ShowPdf,
						setCounterPages
					)
				}
			/>

			<ArchiveModel
				isShown={showArchive !== -1}
				handleClose={handleCloseArchive}
				title={
					employees.data[index]
						? `${employees.data[index].full_name}`
						: ""
				}
				// handleDelete={handleDelete}
				HandleArchive={() =>
					!archive ? handleArchive() : HandleUnArchive()
				}
				isDeleting={employees.isDeleting}
				isArchive={true}
				isUnarchive={archive}
			/>
			<DeleteModal
				isShown={showDelete !== -1}
				handleClose={handleCloseDelete}
				title={
					employees.data[index]
						? `${employees.data[index].full_name}`
						: ""
				}
				handleDelete={handleDelete}
				// HandleArchive={ () =>  !archive ? handleArchive() : HandleUnArchive()}
				isDeleting={employees.isDeleting}
				// isArchive = {true}
				// isUnarchive={archive}
			/>
		</div>
	);
};

ResumesTable.propTypes = {
	getEmployeesAction: PropTypes.func.isRequired,
	deleteResumeAction: PropTypes.func.isRequired,
	employees: PropTypes.shape({
		data: PropTypes.array,
		meta: PropTypes.shape({
			pagination: PropTypes.shape({
				current_page: PropTypes.number,
				total_pages: PropTypes.number,
			}),
		}),
		filters: PropTypes.array,
		isLoading: PropTypes.bool,
		hasError: PropTypes.bool,
		isRestoring: PropTypes.bool,
		isDeleting: PropTypes.bool,
	}).isRequired,
};

const mapStateToProps = (state) => ({
	employees: state.employees,
});

export default connect(mapStateToProps, {
	getResumesAction,
	deleteResumeAction,
	getEmployeesAction,
	ArchiveResume,
	UnArchiveResume,
	FilterEmployees,
})(ResumesTable);
